<template>
  <div class="levelOneDiv1" style="background-color: #fff;">
    <div class="small_title">
      <templateTitle :title="title" :isBack="true" @handleBack="btnClose"></templateTitle>

      <el-button v-if="type == 'view'" size="mini" type="primary" plain icon="el-icon-edit" @click="editCols"
        >编辑</el-button
      >
    </div>

    <div v-if="type == 'add'">
      <el-form
        :inline="true"
        :model="searchObj"
        :rules="rules"
        ref="form"
        style="align-items: center;margin-bottom: 4px;"
        class="user-search flex-between"
        @submit.native.prevent
        label-position="top"
      >
        <div>
          <el-form-item class="budgetYear" label="新增年度" prop="year">
            <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div v-if="type == 'add'" class="small_title">
      <templateDivider title="人力和物力投入情况"></templateDivider>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="addCols">增加</el-button>
    </div>

    <el-row>
      <!-- 纵向表头设计 -->
      <el-col :span="5" style="background-color: #F9FAFE;">
        <el-row v-if="type == 'add' || type == 'edit'">
          <div class="leftTitletStyle">操作</div>
        </el-row>
        <el-row>
          <div class="leftTitletStyle">部门或单位名称</div>
        </el-row>

        <el-row>
          <div class="leftTitletStyle1">工作职责</div>
        </el-row>

        <el-row>
          <div class="leftTitletStyle">工作小组（个）</div>
        </el-row>

        <!-- 居住地方 -->
        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">工作人员</div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">合计</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">监测评估人员（个）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">项目稽查人员（个）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">会计财务人员（个）</div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">数码相机（部）</div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">数码摄像机（部）</div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">笔记本电脑（台）</div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">打印（扫描）一体机（台）</div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">传真机（台）</div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">轿车/越野车（台）</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>

      <el-col :span="2" style="background-color: #F9FAFE;">
        <el-row>
          <div class="leftTotalStyle">合计</div>
        </el-row>

        <el-row v-if="type == 'add' || type == 'edit'">
          <div class="leftTotalStyle"></div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle1"></div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('workTeamCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('totalStaffCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('monitoringAssessmentStaffCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('projectInspectionStaffCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('accountingFinanceStaffCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('digitalCamerasCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('digitalVideoCamerasCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('laptopsCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('printerScannerMachinesCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('faxMachinesCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('carsSUVsCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <!-- <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('carsSUVsCount') }}</div>
            </div>
          </el-col>
        </el-row> -->
      </el-col>

      <!-- 2.纵向数据展示 -->
      <el-col :span="17">
        <div class="main_box">
          <!-- -纵列数据遍历 -->
          <div :style="boxWidth" class="clearFloat">
            <div>
              <el-row class="flex">
                <el-row style="width: 330px;" v-for="(col, index) in listData" :key="col.id">
                  <el-col :span="24">
                    <el-row v-if="type == 'add'">
                      <div class="leftTitletStyle">
                        <el-link type="danger" @click="removeCol(index)">删除</el-link>
                      </div>
                    </el-row>

                    <el-row v-if="type == 'edit'">
                      <div class="leftTitletStyle"></div>
                    </el-row>

                    <el-col>
                      <template v-for="key1 in Object.keys(col)">
                        <el-row v-if="key1 == 'departmentName'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="text"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == '' ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'jobResponsibilities'">
                          <div class="contentStyle1">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              type="textarea"
                              resize="none"
                              :rows="10"
                              placeholder="请输入"
                              v-model="col[key1]"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == '' ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'workTeamCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'totalStaffCount'">
                          <div class="contentStyle">
                            <div class="box_view">
                              {{
                                (Number(col['monitoringAssessmentStaffCount']) * 1000 +
                                  Number(col['projectInspectionStaffCount']) * 1000 +
                                  Number(col['accountingFinanceStaffCount']) * 1000 +
                                  Number(col['digitalCamerasCount']) * 1000 +
                                  Number(col['digitalVideoCamerasCount']) * 1000 +
                                  Number(col['laptopsCount']) * 1000 +
                                  Number(col['printerScannerMachinesCount']) * 1000 +
                                  Number(col['faxMachinesCount']) * 1000 +
                                  Number(col['carsSUVsCount']) * 1000) /
                                  1000
                              }}
                            </div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'monitoringAssessmentStaffCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'projectInspectionStaffCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'accountingFinanceStaffCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'digitalCamerasCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'digitalVideoCamerasCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'laptopsCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'printerScannerMachinesCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'faxMachinesCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'carsSUVsCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>
                      </template>
                    </el-col>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="import_opration" v-if="type == 'add' || type == 'edit'">
      <el-button size="small" plain @click="btnClose()">取消</el-button>
      <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
    </div>
  </div>
</template>

<script>
import templateTitle from '@/components/templateView/templateTitle.vue'
import templateDivider from '@/components/templateView/templateDivider.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'
import { _getLevelRegion } from '@/api/keepHome'

import {
  _InformationAdd,
  _InformationInfo,
  _InformationEdit
} from '@/api/modular/postImmigrationService/workingCondition/InputSituation.js'

export default {
  data() {
    return {
      type: '',
      row: {},
      title: '',
      input: '',
      listData: [],
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择年度', trigger: 'blur' }]
      },
      loading: false,
      options: [],
      treeProps: { label: 'name', value: 'id', children: 'childRegions', checkStrictly: true },
      levalRegion: []
    }
  },
  // 注册组件
  components: {
    templateTitle,
    templateDivider,
    budgetYear
  },
  computed: {
    //
    boxWidth() {
      let num = 330 * this.listData.length
      return `width:${num}px`
    }
  },
  created() {
    this.getLevelRegion()
  },
  mounted() {},

  methods: {
    handleChange(value, index) {
      let obj = this.getPids(value)
      this.listData[index].countyId = obj.id
      this.listData[index].county = obj.name
      this.listData[index].countyPids = obj.pids
    },
    // 获取选中节点的pids值
    getPids(selectedOptions) {
      const selectedNode = this.findNodeById(this.levalRegion, selectedOptions[selectedOptions.length - 1])
      return selectedNode ? selectedNode : []
    },

    // 在数据源中查找指定ID的节点
    findNodeById(nodes, id) {
      for (const node in nodes) {
        if (nodes[node].id === id) {
          return nodes[node]
        }
        if (nodes[node].childRegions && nodes[node].childRegions.length > 0) {
          const foundNode = this.findNodeById(nodes[node].childRegions, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getLevelRegion() {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childRegions.length < 1) {
          data[i].childRegions = undefined
        } else {
          this.formatTreeData(data[i].childRegions)
        }
      }
      return data
    },
    calculateTheTotal(name) {
      let sum = 0
      if (name == 'totalStaffCount') {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum +=
            (Number(ele['monitoringAssessmentStaffCount']) * 100 +
              Number(ele['projectInspectionStaffCount']) * 100 +
              Number(ele['accountingFinanceStaffCount']) * 100 +
              Number(ele['digitalCamerasCount']) * 100 +
              Number(ele['digitalVideoCamerasCount']) * 100 +
              Number(ele['laptopsCount']) * 100 +
              Number(ele['printerScannerMachinesCount']) * 100 +
              Number(ele['faxMachinesCount']) * 100 +
              Number(ele['carsSUVsCount']) * 100) /
            100
        }
      } else {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum += Number(ele[name])
        }
      }

      return sum
    },
    refreshYear(year) {
      this.searchObj.year = year
    },
    add(type, row) {
      this.type = type
      this.row = row
      this.title = '新增'
    },
    view(type, row) {
      this.type = type
      this.row = row

      this.title = type == 'view' ? '详情' : '查看统计表'
      this.getBasicInfo()
    },
    editCols() {
      this.type = 'edit'
      this.title = '编辑'
      // this.$nextTick(() => {
      //   this.$refs.table.doLayout()
      // })
    },
    btnClose() {
      this.$emit('handleBack')
    },
    addCols() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let obj = {
            year: this.searchObj.year, //年份
            departmentName: '',
            jobResponsibilities: '',
            workTeamCount: null,
            totalStaffCount: null,
            monitoringAssessmentStaffCount: null,
            projectInspectionStaffCount: null,
            accountingFinanceStaffCount: null,
            digitalCamerasCount: null,
            digitalVideoCamerasCount: null,
            laptopsCount: null,
            printerScannerMachinesCount: null,
            faxMachinesCount: null,
            carsSUVsCount: null
          }

          this.listData.splice(this.listData.length, 0, obj)
        }
      })
    },
    removeCol(index) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.listData.splice(index, 1)
          this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    refreshTable() {
      this.getBasicInfo()
    },
    getBasicInfo() {
      let params = {
        id: this.row.id
      }
      _InformationInfo(params).then(res => {
        if (res.code == 200) {
          this.listData = [res.data]
        }
      })
    },
    submitForm() {
      console.log(this.listData, '===submitForm===')
      switch (this.type) {
        case 'add':
          _InformationAdd(this.listData).then(res => {
            if (res.code == 200) {
              this.$message.success('添加成功')
              this.btnClose()
            } else {
              this.$message.error(res.message)
            }
          })
          break
        case 'edit':
          _InformationEdit(this.listData[0]).then(res => {
            if (res.code == 200) {
              this.type = 'view'
              this.$message.success('修改成功')
              this.getBasicInfo()
            } else {
              this.$message.error(res.message)
            }
          })

          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/formview.scss';
</style>
